/**
 * Configuration for all alerts
 */
import ROUTES, { BASE_ROUTES, Routes, SHOPPING_ROUTES } from 'helpers/routes'

import {
  ADD_PAYMENT,
  ADD_TO_CART,
  CART,
  CHANGE_PROFILE,
  CHECKOUT,
  HELP,
  ORDER_HISTORY,
  PLACE_ORDER,
  Alerts,
  alertTypes,
  BLOCK_UPLOAD_ID
} from './types'

type AlertConfig = {
  [K in Alerts]?: {
    banner?: {
      visibleRoutes: Routes[]
    }
    modal?: {
      hideIfQueryParams?: string[]
      showOnLoadRoutes: Routes[]
      showOnAction: string[]
      visibleRoutes: Routes[]
    }
    preventedActions?: string[]
  }
}

const config: AlertConfig = {
  [alertTypes.AGE_GATE]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES,
      showOnAction: [],
      visibleRoutes: BASE_ROUTES
    }
  },
  [alertTypes.ANNOUNCEMENT]: {
    modal: {
      hideIfQueryParams: [CART, HELP, ORDER_HISTORY],
      showOnLoadRoutes: SHOPPING_ROUTES,
      showOnAction: [],
      visibleRoutes: SHOPPING_ROUTES
    }
  },
  [alertTypes.AT_DAILY_LIMIT]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [CHECKOUT, PLACE_ORDER]
  },
  [alertTypes.CANCELED]: {
    banner: {
      visibleRoutes: [ROUTES.CHECKOUT, ROUTES.SHOW_ORDER]
    }
  },
  [alertTypes.CART_EMPTY]: {
    preventedActions: [CHECKOUT, ADD_PAYMENT, PLACE_ORDER]
  },
  [alertTypes.CART_EXPIRED]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.PROFILE]),
      showOnAction: [],
      visibleRoutes: []
    }
  },
  [alertTypes.CART_SIZE_MISMATCH]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT]),
      showOnAction: [],
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.CHANGED_PASSWORD]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES,
      showOnAction: [],
      visibleRoutes: BASE_ROUTES
    }
  },
  [alertTypes.CHECKOUT]: {
    banner: {
      visibleRoutes: [ROUTES.CHECKOUT]
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.CHECKOUT_MESSAGE]: {
    banner: {
      visibleRoutes: [ROUTES.CHECKOUT]
    }
  },
  [alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT]),
      showOnAction: [],
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.CREDIT]: {
    banner: {
      visibleRoutes: BASE_ROUTES
    }
  },
  [alertTypes.CASH_ONLY]: {
    modal: {
      showOnLoadRoutes: [ROUTES.CHECKOUT],
      showOnAction: [],
      visibleRoutes: [ROUTES.CHECKOUT]
    }
  },
  [alertTypes.DISCOUNTED_DELIVERY_FEE]: {
    banner: {
      visibleRoutes: SHOPPING_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.DISCOUNTED_DELIVERY_BYPASS]: {
    banner: {
      visibleRoutes: SHOPPING_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.DEPOT_MISMATCH]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT]),
      showOnAction: [],
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.ETA_LOADING]: {
    preventedActions: [ADD_PAYMENT, PLACE_ORDER]
  },
  [alertTypes.ETA_LOADING_ERROR]: {
    preventedActions: [ADD_PAYMENT, PLACE_ORDER]
  },
  [alertTypes.EXPIRED_ID]: {
    banner: {
      visibleRoutes: [ROUTES.MENU, ROUTES.GROUPS]
    }
  },
  [alertTypes.EXPIRED_REC]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.EXISTING_EMAIL_ADDRESS]: {
    banner: {
      visibleRoutes: [ROUTES.PROFILE]
    },
    modal: {
      showOnLoadRoutes: [ROUTES.INVITE, ROUTES.PROMO_CODE, ROUTES.SIGNUP, ROUTES.VERIFY],
      showOnAction: [],
      visibleRoutes: [ROUTES.INVITE, ROUTES.PROMO_CODE, ROUTES.SIGNUP, ROUTES.VERIFY]
    }
  },
  [alertTypes.EXISTING_PHONE_NUMBER]: {
    banner: {
      visibleRoutes: [ROUTES.PROFILE]
    },
    modal: {
      showOnLoadRoutes: [ROUTES.SIGNUP, ROUTES.VERIFY],
      showOnAction: [],
      visibleRoutes: [ROUTES.SIGNUP, ROUTES.VERIFY]
    }
  },
  [alertTypes.FREE_DELIVERY]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.ID_DECLINED]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.ID_INVALID_IN_AREA]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [CHECKOUT, PLACE_ORDER, ADD_PAYMENT]
  },
  [alertTypes.ID_REQUIRED]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.ID_PENDING]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER, CHECKOUT]
  },
  [alertTypes.IMAGE_UPLOAD_ERROR]: {
    modal: {
      showOnLoadRoutes: [ROUTES.PROFILE, ROUTES.VERIFY],
      showOnAction: [],
      visibleRoutes: [ROUTES.PROFILE, ROUTES.VERIFY]
    }
  },
  [alertTypes.INVALID_LOGIN]: {
    banner: {
      visibleRoutes: [ROUTES.LOGIN]
    }
  },
  [alertTypes.INVALID_STREET_ADDRESS]: {
    banner: {
      visibleRoutes: [ROUTES.CHECKOUT]
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.LOCATION]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER, ADD_PAYMENT]
  },
  [alertTypes.LOCATION_OUT_OF_SERVICE]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES,
      showOnAction: [CHECKOUT, ADD_TO_CART],
      visibleRoutes: BASE_ROUTES
    },
    preventedActions: [CHECKOUT, PLACE_ORDER, ADD_PAYMENT, ADD_TO_CART]
  },
  [alertTypes.LOGIN_OR_SIGNUP_ERROR]: {
    banner: {
      visibleRoutes: [ROUTES.INVITE, ROUTES.PROMO_CODE, ROUTES.LOGIN, ROUTES.SIGNUP, ROUTES.RESET]
    }
  },
  [alertTypes.NEED_TO_SHOW_ID]: {
    modal: {
      showOnLoadRoutes: [ROUTES.SHOW_ORDER],
      showOnAction: [],
      visibleRoutes: [ROUTES.SHOW_ORDER]
    }
  },
  [alertTypes.NEED_2FA_REAUTH]: {
    modal: {
      showOnLoadRoutes: [],
      showOnAction: [CHANGE_PROFILE],
      visibleRoutes: [ROUTES.PROFILE]
    }
  },
  [alertTypes.HAS_LINKED_ACCOUNTS]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    modal: {
      showOnLoadRoutes: [ROUTES.PROFILE],
      showOnAction: [BLOCK_UPLOAD_ID],
      visibleRoutes: [ROUTES.PROFILE]
    },
    preventedActions: [BLOCK_UPLOAD_ID]
  },
  [alertTypes.NO_DRIVERS]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [CHECKOUT, ADD_PAYMENT, PLACE_ORDER]
  },
  // There is no banner, but we're leaving this here for the preventActions functionality
  [alertTypes.NO_PAYMENT_SELECTED]: {
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.NON_EXISTENT_EMAIL]: {
    modal: {
      showOnLoadRoutes: [ROUTES.RESET_PASSWORD],
      showOnAction: [],
      visibleRoutes: [ROUTES.RESET_PASSWORD]
    }
  },
  [alertTypes.PHONE_VERIFICATION_INCOMPLETE]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.PROFILE]),
      showOnAction: [],
      visibleRoutes: BASE_ROUTES.concat([ROUTES.PROFILE])
    }
  },
  [alertTypes.PRODUCTS_UNAVAILABLE]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [CHECKOUT, PLACE_ORDER]
  },
  [alertTypes.REMOVED_PROMO]: {
    modal: {
      showOnLoadRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT]),
      showOnAction: [],
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.SAMPLE_MENU]: {
    preventedActions: [CHECKOUT, PLACE_ORDER, ADD_PAYMENT, ADD_TO_CART]
  },
  [alertTypes.STORE_CLOSED]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    modal: {
      showOnLoadRoutes: [ROUTES.CHECKOUT],
      showOnAction: [ADD_TO_CART],
      visibleRoutes: BASE_ROUTES
    },
    preventedActions: [ADD_TO_CART, CHECKOUT, PLACE_ORDER]
  },
  [alertTypes.STORE_CLOSED_SCHEDULED_DELIVERY_ON]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.STORE_CLOSING_SOON]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    }
  },
  [alertTypes.UNDER_AGE_MINIMUM]: {
    modal: {
      showOnLoadRoutes: [ROUTES.CHECKOUT],
      showOnAction: [],
      visibleRoutes: [ROUTES.CHECKOUT]
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.UNDER_MINIMUM]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    modal: {
      showOnLoadRoutes: [ROUTES.CHECKOUT],
      showOnAction: [PLACE_ORDER],
      visibleRoutes: [ROUTES.CHECKOUT]
    },
    preventedActions: [CHECKOUT, PLACE_ORDER, ADD_PAYMENT]
  },
  [alertTypes.VERIFICATION_DECLINED]: {
    modal: {
      showOnLoadRoutes: [ROUTES.PROFILE],
      showOnAction: [],
      visibleRoutes: [ROUTES.PROFILE]
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.VERIFICATION_PENDING]: {
    banner: {
      visibleRoutes: BASE_ROUTES.concat([ROUTES.CHECKOUT])
    },
    preventedActions: [PLACE_ORDER]
  },
  [alertTypes.VERIFICATION_PROCESS_ERROR]: {
    banner: {
      visibleRoutes: [ROUTES.VERIFY, ROUTES.LOGIN]
    }
  },
  [alertTypes.VERIFICATION_RESEND_SUCCESSFUL]: {
    banner: {
      visibleRoutes: [ROUTES.VERIFY]
    }
  },
  [alertTypes.VERIFY_PHONE]: {
    modal: {
      showOnLoadRoutes: [],
      showOnAction: [CHECKOUT],
      visibleRoutes: BASE_ROUTES
    },
    preventedActions: [PLACE_ORDER, ADD_PAYMENT]
  },
  [alertTypes.VERSION_MISMATCH]: {
    modal: {
      showOnLoadRoutes: SHOPPING_ROUTES.concat([ROUTES.CHECKOUT]),
      showOnAction: [],
      visibleRoutes: SHOPPING_ROUTES.concat([ROUTES.CHECKOUT])
    }
  }
}
export default config
