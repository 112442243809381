/**
 * Determines the sorting order for errors.
 * If there is more than 1 banner or more than 1 modal for errors,
 * the first error listed here will show up
 */

import { Alerts, alertTypes } from './types'

const hierarchy: Alerts[] = [
  alertTypes.AGE_GATE,
  alertTypes.CHANGED_PASSWORD,
  alertTypes.VERIFICATION_PROCESS_ERROR,
  alertTypes.VERIFICATION_RESEND_SUCCESSFUL,
  alertTypes.PHONE_VERIFICATION_INCOMPLETE,
  alertTypes.NON_EXISTENT_EMAIL,
  alertTypes.EXISTING_EMAIL_ADDRESS,
  alertTypes.EXISTING_PHONE_NUMBER,
  alertTypes.IMAGE_UPLOAD_ERROR,
  alertTypes.VERIFICATION_DECLINED,
  alertTypes.ID_DECLINED,
  alertTypes.ID_REQUIRED,
  alertTypes.ID_INVALID_IN_AREA,
  alertTypes.VERIFICATION_PENDING,
  alertTypes.VERIFY_PHONE,
  alertTypes.EXPIRED_REC,
  alertTypes.CASH_ONLY,
  alertTypes.UNDER_AGE_MINIMUM,
  alertTypes.STORE_CLOSED,
  alertTypes.ID_PENDING,
  alertTypes.AT_DAILY_LIMIT,
  alertTypes.PRODUCTS_UNAVAILABLE,
  alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED,
  alertTypes.UNDER_MINIMUM,
  alertTypes.NO_DRIVERS,
  alertTypes.ETA_LOADING_ERROR,
  alertTypes.LOCATION,
  alertTypes.LOCATION_OUT_OF_SERVICE,
  alertTypes.INVALID_STREET_ADDRESS,
  alertTypes.REMOVED_PROMO,
  alertTypes.CANCELED,
  alertTypes.CART_EMPTY,
  alertTypes.CHECKOUT,
  alertTypes.CREDIT,
  alertTypes.INVALID_LOGIN,
  alertTypes.LOGIN_OR_SIGNUP_ERROR,
  alertTypes.ANNOUNCEMENT,
  alertTypes.DEPOT_MISMATCH,
  alertTypes.CART_SIZE_MISMATCH,
  alertTypes.CART_EXPIRED,
  alertTypes.NEED_TO_SHOW_ID,
  alertTypes.NO_PAYMENT_SELECTED,
  alertTypes.STORE_CLOSING_SOON,
  alertTypes.STORE_CLOSED_SCHEDULED_DELIVERY_ON,
  alertTypes.CHECKOUT_MESSAGE,
  alertTypes.FREE_DELIVERY,
  alertTypes.DISCOUNTED_DELIVERY_FEE,
  alertTypes.DISCOUNTED_DELIVERY_BYPASS,
  alertTypes.VERSION_MISMATCH,
  alertTypes.EXPIRED_ID,
  alertTypes.NEED_2FA_REAUTH,
  alertTypes.HAS_LINKED_ACCOUNTS
]

// NEED_2FA_REAUTH MUST STAY BELOW PHONE_VERIFICATION_INCOMPLETE IN THIS LIST!
// We need a phone number for 2FA - but PHONE_VERIFICATION_INCOMPLETE means we don't have a verified phone number to send to
// In that case, we need the user to see the modal to verify their phone number before they see the 2FA modal

const fullHierarchy: Alerts[] = [].concat(hierarchy)

Object.values(alertTypes).forEach((key) => {
  if (hierarchy.indexOf(key) === -1) {
    fullHierarchy.push(key)
  }
})

export default fullHierarchy
