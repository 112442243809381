import fecha from 'fecha'
import isEmptyObject from 'is-empty-object'
import { createSelector } from 'reselect'

import { isCartEmpty, getCartSubtotal, getCartSizeMismatch, getCartExpired } from '@/redux/cart/selectors'
import { closedMessage } from 'helpers/alerts'
import noDeliveryEnums from 'helpers/alerts/no-delivery-enums'
import {
  EXISTING_EMAIL_ERROR,
  EXISTING_PHONE_ERROR,
  WRONG_PASSWORD_ERROR,
  AVAILABLE_MENUS,
  SPLIT_TREATMENTS
} from 'helpers/constants'
import { getUppercaseSlug } from 'helpers/text'
import { getAnnouncementId } from 'redux/announcements/reducer'
import { getDismissedAnnouncements } from 'redux/announcements/selectors'
import { getHasHydrated } from 'redux/app/selectors'
import {
  getAuthError,
  getInvalidPasswordRecoveryAttempt,
  getInvalidLoginAttempt,
  isLoggedIn,
  getProfileChangeAttempt
} from 'redux/auth/selectors'
import {
  getDepotMismatch,
  getCheckoutMessage,
  getCheckoutMessageStatusCode,
  getCheckoutError
} from 'redux/checkout/selectors'
import { getEta, getEtaError } from 'redux/eta/selectors'
import { getExpiredIDExpFlag } from 'redux/experiments/selectors'
import { AGE_MINIMUM, SAN_JOSE } from 'redux/helpers/location'
import { getEtaLoading, getAddressLoading } from 'redux/loading/selectors'
import {
  getStoreClosed,
  getOpenTime,
  getCloseTime,
  getMissingActiveLocation,
  getNoAvailableDepot,
  getInSampleLocation,
  getActiveDepot,
  getDepotMinimumOrder,
  getPotentialAddress,
  getIsExactAddress,
  getAllDayDepot,
  getHasScheduledDelivery
} from 'redux/location/selectors'
import { getAnnouncementProducts, getMenuState } from 'redux/menu/selectors'
import { getCurrentPath, getCurrentQueryParams } from 'redux/misc-selectors/app'
import { getIsOrderCanceledByDriver } from 'redux/order-status/selectors'
import { isCashOnly, getNeedsPaymentMethod } from 'redux/payments/selectors'
import {
  BASIC_STATUSES,
  getBasicStatus,
  getIsPhonePresent,
  getCreditAmount,
  getProfileStatus,
  getAge,
  getIsPhoneVerified,
  getPasswordChangeStatus,
  getDeclinedReasonIds,
  DECLINE_REASONS
} from 'redux/profile/selectors'
import { getRemovedPromo } from 'redux/promo/selectors'
import {
  getPriceInfo,
  getIsDeliveryFeeBypassed,
  getIsDiscountedDeliveryFee,
  getIsDiscountedDeliveryBypass,
  getIsDeliveryPastMinimum
} from 'redux/quote/selectors'
import { getUserState } from 'redux/user/selectors'
import {
  getVerificationProcessErrors,
  getVerificationResendStatus,
  isIdValidInArea
} from 'redux/verification/selectors'

import config from './config'
import hierarchy from './config/hierarchy'
import {
  alertTypes,
  BANNER,
  MODAL,
  ADD_TO_CART,
  CHECKOUT,
  ADD_PAYMENT,
  PLACE_ORDER,
  BLOCK_UPLOAD_ID
} from './config/types'
import { isVisibleForType, showOnLoad } from './config/visibility-helpers'

const EMPTY_OBJECT = {}
const getMultiAlertSet = (alertSet) => (!isEmptyObject(alertSet) ? alertSet : EMPTY_OBJECT)
const createAlertSet = (type) => ({ [type]: true })

const getUnderMinimum = createSelector(
  [isCartEmpty, getCartSubtotal, getDepotMinimumOrder],
  (isEmptyCart, cartSubtotal, depotMinimumOrder) => {
    if (!isEmptyCart) {
      return cartSubtotal && cartSubtotal < depotMinimumOrder
    }
  }
)

const getAlertVisibility = (state) => state.alert

export const getUnderMinimumAmount = createSelector(
  [getCartSubtotal, getDepotMinimumOrder],
  (cartSubtotal, depotMinimumOrder) => {
    return depotMinimumOrder - cartSubtotal
  }
)

export const getClosedMessage = createSelector([getOpenTime], (openTime) => {
  if (!openTime) return null
  const openTimeString = fecha.format(openTime, 'h:mm A')
  return closedMessage(openTimeString)
})

const getAppVersion = (state) => state.app.versionMisMatch
export const getAppVersionMisMatch = createSelector([getAppVersion], (versionMisMatch) => {
  if (versionMisMatch) {
    return createAlertSet(alertTypes.VERSION_MISMATCH)
  }

  return EMPTY_OBJECT
})

export const getAnnouncements = createSelector(
  [getAnnouncementProducts, getInSampleLocation, getDismissedAnnouncements, getStoreClosed],
  (announcements, isInSampleLocation, dismissedAnnouncements, storeClosed) => {
    if (announcements.length > 0) {
      // filter out announcements the user has already dismissed
      const notDismissed = announcements.filter((announcement) => {
        // ADDRESS THIS BEFORE MERGING NEXTJS
        return dismissedAnnouncements.indexOf(getAnnouncementId(announcement.id)) === -1
      })

      // if there is an active, not previously dismissed announcement
      // and the user isn't on the sample menu and the store isn't closed,
      // show the announcement modal
      if (!isInSampleLocation && !storeClosed && notDismissed.length > 0) {
        return createAlertSet(alertTypes.ANNOUNCEMENT)
      }
    }

    return EMPTY_OBJECT
  }
)

const getPromoMessage = createSelector([getCreditAmount, getInSampleLocation], (creditAmount, inSampleLocation) => {
  if (creditAmount > 0.1 && !inSampleLocation) {
    return createAlertSet(alertTypes.CREDIT)
  }

  return EMPTY_OBJECT
})

const getExpiredId = createSelector([getUserState, getExpiredIDExpFlag], (currentUserState, expiredIdExpFlag) => {
  const { expiredIdDate } = currentUserState
  if (expiredIdDate && expiredIdExpFlag && expiredIdExpFlag === SPLIT_TREATMENTS.EXPIRED_ID.ON) {
    const expiredId = new Date(expiredIdDate)
    const currentDate = new Date()
    if (currentDate.setHours(0, 0, 0, 0) > expiredId.setHours(0, 0, 0, 0)) {
      return createAlertSet(alertTypes.EXPIRED_ID)
    }
    return EMPTY_OBJECT
  }
  return EMPTY_OBJECT
})

const getFreeDeliveryMessage = createSelector([getActiveDepot], (activeDepot) => {
  // We want users to be able to see that they have free delivery before having
  // to add items to the cart. Currently, info on free delivery is only available
  // from a POST /quote with a cart. Until we can get the depot delivery info
  // on from the GET /places/:placeId call, we'll have to hardcode the depot IDs
  // that we expect to qualify for free delivery.

  const FREE_DELIVERY_DEPOTS = []

  if (activeDepot && FREE_DELIVERY_DEPOTS.indexOf(activeDepot.id) > -1) {
    return createAlertSet(alertTypes.FREE_DELIVERY)
  }

  return EMPTY_OBJECT
})

const getIsDeliveryFeeDiscounted = createSelector(
  [getIsDeliveryFeeBypassed, getIsDiscountedDeliveryFee, getIsDeliveryPastMinimum],
  (isDeliveryFeeBypassed, isDiscountedDeliveryFee, isDeliveryPastMinimum) => {
    const isPriceOverride = isDeliveryFeeBypassed && !isDeliveryPastMinimum
    if (!isPriceOverride && isDiscountedDeliveryFee) {
      return createAlertSet(alertTypes.DISCOUNTED_DELIVERY_FEE)
    }

    return EMPTY_OBJECT
  }
)

const getIsDeliveryBypassDiscounted = createSelector(
  [getIsDeliveryFeeBypassed, getIsDiscountedDeliveryBypass, getIsDeliveryPastMinimum],
  (isDeliveryFeeBypassed, isDiscountedDeliveryBypass, isDeliveryPastMinimum) => {
    const isPriceOverride = isDeliveryFeeBypassed && !isDeliveryPastMinimum
    if (!isPriceOverride && isDiscountedDeliveryBypass) {
      return createAlertSet(alertTypes.DISCOUNTED_DELIVERY_BYPASS)
    }

    return EMPTY_OBJECT
  }
)

const getCheckoutAlert = createSelector([getCheckoutError], (hasCheckoutError) => {
  if (hasCheckoutError) {
    return createAlertSet(alertTypes.CHECKOUT)
  }
  return EMPTY_OBJECT
})

const getAtDailyLimit = createSelector([getCheckoutError], (hasCheckoutError) => {
  if (hasCheckoutError && typeof hasCheckoutError === 'string') {
    if (hasCheckoutError.match(/legal purchase limit/) || hasCheckoutError.match(/one order per day/)) {
      return createAlertSet(alertTypes.AT_DAILY_LIMIT)
    }
  }
  return EMPTY_OBJECT
})

const getIsCanceledByDriver = createSelector([getIsOrderCanceledByDriver], (isCanceledByDriver) => {
  if (isCanceledByDriver) {
    return createAlertSet(alertTypes.CANCELED)
  }
  return EMPTY_OBJECT
})

const getIsCheckoutMessage = createSelector(
  [getCheckoutMessage, getCheckoutMessageStatusCode],
  (message, statusCode) => {
    if (message && statusCode) {
      return createAlertSet(alertTypes.CHECKOUT_MESSAGE)
    }

    return EMPTY_OBJECT
  }
)

const getCheckoutDepotMismatch = createSelector([getDepotMismatch], (isDepotMismatch) => {
  if (isDepotMismatch) {
    return createAlertSet(alertTypes.DEPOT_MISMATCH)
  }

  return EMPTY_OBJECT
})

const getCartMismatch = createSelector([getCartSizeMismatch], (isCartSizeMismatch) => {
  if (isCartSizeMismatch) {
    return createAlertSet(alertTypes.CART_SIZE_MISMATCH)
  }

  return EMPTY_OBJECT
})

const getCartDidExpire = createSelector([getCartExpired], (cartExpired) => {
  if (cartExpired) {
    return createAlertSet(alertTypes.CART_EXPIRED)
  }

  return EMPTY_OBJECT
})

const getWasPromoRemoved = createSelector([getRemovedPromo], (removedPromo) => {
  if (removedPromo) {
    return createAlertSet(alertTypes.REMOVED_PROMO)
  }

  return EMPTY_OBJECT
})

const getDeliveryErrors = createSelector(
  [
    getStoreClosed,
    getAllDayDepot,
    getEta,
    getEtaError,
    getAddressLoading,
    getEtaLoading,
    getIsExactAddress,
    getHasScheduledDelivery
  ],
  (storeClosed, allDay, eta, etaError, addressLoading, etaLoading, isExactAddress, hasScheduledDelivery) => {
    const alertSets = {}

    if (etaError) {
      if (!isExactAddress) {
        alertSets[alertTypes.INVALID_STREET_ADDRESS] = true
      } else {
        alertSets[alertTypes.ETA_LOADING_ERROR] = true
      }
    }

    if (storeClosed && !allDay && !hasScheduledDelivery) alertSets[alertTypes.STORE_CLOSED] = true
    if (storeClosed && hasScheduledDelivery) alertSets[alertTypes.STORE_CLOSED_SCHEDULED_DELIVERY_ON] = true

    switch (eta.reasonCode) {
      case noDeliveryEnums.NONE:
        // no op
        break

      case noDeliveryEnums.LOCATION:
        alertSets[alertTypes.LOCATION] = true
        break

      case noDeliveryEnums.CLOSED:
        // We shouldnt get an eta error from the BE if the store is closed but just in case
        hasScheduledDelivery
          ? (alertSets[alertTypes.STORE_CLOSED_SCHEDULED_DELIVERY_ON] = true)
          : (alertSets[alertTypes.STORE_CLOSED] = true)
        break

      case noDeliveryEnums.NO_DRIVERS:
      case noDeliveryEnums.NO_DRIVERS_EXCEPT_CANCELED:
      case noDeliveryEnums.ALL_DRIVERS_BUSY:
        alertSets[alertTypes.NO_DRIVERS] = true
        break

      case noDeliveryEnums.PRODUCTS_UNAVAILABLE:
        alertSets[alertTypes.PRODUCTS_UNAVAILABLE] = true
        break

      case noDeliveryEnums.INVALID_STREET_ADDRESS:
        alertSets[alertTypes.INVALID_STREET_ADDRESS] = true
        break

      default:
      // no op
    }

    return getMultiAlertSet(alertSets)
  }
)

const getLoginOrSignupAttemptAlerts = createSelector(
  [getInvalidLoginAttempt, getAuthError, getPasswordChangeStatus],
  (invalidLoginAttempt, authError, passwordChangeStatus) => {
    const alertSets = {}
    const profileError = passwordChangeStatus.err
    if (invalidLoginAttempt) alertSets[alertTypes.INVALID_LOGIN] = true

    if (authError || profileError) {
      if (
        getUppercaseSlug(authError) === getUppercaseSlug(EXISTING_EMAIL_ERROR) ||
        getUppercaseSlug(authError) === getUppercaseSlug(WRONG_PASSWORD_ERROR)
      ) {
        alertSets[alertTypes.EXISTING_EMAIL_ADDRESS] = true
      } else {
        alertSets[alertTypes.LOGIN_OR_SIGNUP_ERROR] = true
      }
    }

    if (passwordChangeStatus && !profileError) alertSets[alertTypes.CHANGED_PASSWORD] = true

    return getMultiAlertSet(alertSets)
  }
)

const getVerificationErrors = createSelector(
  [
    getIsPhonePresent,
    getIsPhoneVerified,
    getBasicStatus,
    getIsExactAddress,
    getMenuState,
    getActiveDepot,
    isIdValidInArea,
    getDeclinedReasonIds
  ],
  (
    isPhonePresent,
    isPhoneVerified,
    idStatus,
    isExactAddress,
    currentMenu,
    activeDepot,
    idValidInArea,
    declineReasonIds
  ) => {
    const isIdUploaded = idStatus !== BASIC_STATUSES.NONE && idStatus !== BASIC_STATUSES.INCOMPLETE
    const alertSets = {}
    // phone verification incomplete
    if (!isPhonePresent || !isPhoneVerified) alertSets[alertTypes.PHONE_VERIFICATION_INCOMPLETE] = true

    if (!isIdUploaded) {
      // if no ID uploaded show ID required banner
      alertSets[alertTypes.ID_REQUIRED] = true
    } else if (idStatus === BASIC_STATUSES.PENDING) {
      // if ID is pending
      alertSets[alertTypes.ID_PENDING] = true
    }

    // Show warning for when a valid ID becomes invalidated
    if (!idValidInArea) {
      alertSets[alertTypes.ID_INVALID_IN_AREA] = true
    }

    // add the conditional for 'entity_match_detected/'id_repeat_detection'/'id_duplicate_detected' so we create an alertSet for other generic
    // cases where verification might fail
    if (idStatus === BASIC_STATUSES.DECLINED && !declineReasonIds.includes(DECLINE_REASONS.STATE_ID_DUPLICATED)) {
      // if ID is declined
      alertSets[alertTypes.VERIFICATION_DECLINED] = true
      alertSets[alertTypes.ID_DECLINED] = true
    }

    return getMultiAlertSet(alertSets)
  }
)

const getVerificationFlowErrors = createSelector([getVerificationProcessErrors], (verificationProcessError) => {
  const alertSets = {}

  if (verificationProcessError.upload) {
    alertSets[alertTypes.IMAGE_UPLOAD_ERROR] = true
  }

  if (verificationProcessError.generic) {
    if (getUppercaseSlug(verificationProcessError.generic) === getUppercaseSlug(EXISTING_PHONE_ERROR)) {
      alertSets[alertTypes.EXISTING_PHONE_NUMBER] = true
    } else {
      alertSets[alertTypes.VERIFICATION_PROCESS_ERROR] = true
    }
  }

  return getMultiAlertSet(alertSets)
})

const getVerificationLinkResendStatus = createSelector([getVerificationResendStatus], (verificationResendStatus) => {
  if (verificationResendStatus) {
    return createAlertSet(alertTypes.VERIFICATION_RESEND_SUCCESSFUL)
  }
  return EMPTY_OBJECT
})

const getStatusErrors = createSelector([getProfileStatus], (status) => {
  // check exactly equal to false to make sure this doesn't trigger when it's undefined instead.
  if (status.isAgreementAccepted === false) {
    return createAlertSet(alertTypes.COLLECTIVE_AGREEMENT_NOT_SIGNED)
  }

  return EMPTY_OBJECT
})

const getCartErrors = createSelector([isCartEmpty, getUnderMinimum], (isEmptyCart, isUnderMinimum) => {
  if (isEmptyCart) {
    return createAlertSet(alertTypes.CART_EMPTY)
  } else if (isUnderMinimum) {
    return createAlertSet(alertTypes.UNDER_MINIMUM)
  }

  return EMPTY_OBJECT
})

const getSampleMenu = createSelector([getMissingActiveLocation], (missingActiveLocation) => {
  if (missingActiveLocation) {
    return createAlertSet(alertTypes.SAMPLE_MENU)
  }
  return EMPTY_OBJECT
})

const getLocationOutOfServiceArea = createSelector(
  [getNoAvailableDepot, getPotentialAddress, getAddressLoading, getHasHydrated],
  (noAvailableDepot, potentialAddress, addressLoading, hasHydrated) => {
    if (!addressLoading && hasHydrated && potentialAddress && !isEmptyObject(potentialAddress)) {
      const noMenuAvailable =
        !potentialAddress.menus || !potentialAddress.menus.some((item) => AVAILABLE_MENUS.includes(item))
      if (!potentialAddress.depot || noAvailableDepot || noMenuAvailable) {
        return createAlertSet(alertTypes.LOCATION_OUT_OF_SERVICE)
      }
    }

    return EMPTY_OBJECT
  }
)

const getPasswordRecoveryErrors = createSelector(
  [getInvalidPasswordRecoveryAttempt],
  (invalidPasswordRecoveryAttempt) => {
    if (invalidPasswordRecoveryAttempt) {
      if (invalidPasswordRecoveryAttempt.indexOf('@') > -1) {
        return createAlertSet(alertTypes.NON_EXISTENT_EMAIL)
      } else {
        return createAlertSet(alertTypes.NON_EXISTENT_PHONE_NUMBER)
      }
    }

    return EMPTY_OBJECT
  }
)

export const getUnderAgeMinimumError = createSelector([getActiveDepot, getAge], (activeDepot, age) => {
  if (age < AGE_MINIMUM && (activeDepot.id === SAN_JOSE.GUILD || activeDepot.id === SAN_JOSE.CALIVA)) {
    return createAlertSet(alertTypes.UNDER_AGE_MINIMUM)
  }
  return EMPTY_OBJECT
})

export const getNeedToShowIDs = createSelector([getActiveDepot], (activeDepot) => {
  if (activeDepot.id === SAN_JOSE.GUILD || activeDepot.id === SAN_JOSE.CALIVA) {
    return createAlertSet(alertTypes.NEED_TO_SHOW_ID)
  }

  return EMPTY_OBJECT
})

export const getNeedsPaymentType = createSelector(
  [getNeedsPaymentMethod, getPriceInfo],
  (needsPaymentType, priceInfo) => {
    if (needsPaymentType && priceInfo.chargeAmount > 0) {
      return createAlertSet(alertTypes.NO_PAYMENT_SELECTED)
    }

    return EMPTY_OBJECT
  }
)

const getIsClosingSoon = createSelector([getCloseTime, getAllDayDepot], (closeTime, allDay) => {
  // if depot is 24 hours, return early
  if (!closeTime || allDay) return null
  const now = new Date()
  if (now.setHours(now.getHours() + 1) > closeTime) {
    return createAlertSet(alertTypes.STORE_CLOSING_SOON)
  }

  return EMPTY_OBJECT
})

const getIsCashOnly = createSelector([isCashOnly], (isCashOnly) => {
  if (isCashOnly) {
    return createAlertSet(alertTypes.CASH_ONLY)
  }

  return EMPTY_OBJECT
})

const getHasVerifiedAgeCookie = (state) => state.cookies.hasVerifiedAge
const getShouldShowAgeGate = createSelector(
  [getHasHydrated, isLoggedIn, getHasVerifiedAgeCookie],
  (hasHydrated, isLoggedInBool, hasVerifiedAge) => {
    if (hasHydrated && !isLoggedInBool && !hasVerifiedAge) {
      return createAlertSet(alertTypes.AGE_GATE)
    }

    return EMPTY_OBJECT
  }
)

const shouldShow2faModal = createSelector([getProfileChangeAttempt], (profileChangeAttempted) => {
  return profileChangeAttempted ? createAlertSet(alertTypes.NEED_2FA_REAUTH) : EMPTY_OBJECT
})

const shouldShowDuplicateAccountModal = createSelector([getDeclinedReasonIds], (declineReasonIds) => {
  if (declineReasonIds.includes(DECLINE_REASONS.STATE_ID_DUPLICATED)) {
    return createAlertSet(alertTypes.HAS_LINKED_ACCOUNTS)
  } else {
    return EMPTY_OBJECT
  }
})

export const getErrors = createSelector(
  [
    getLocationOutOfServiceArea,
    getSampleMenu,
    getCheckoutAlert,
    getIsCanceledByDriver,
    getAtDailyLimit,
    getStatusErrors,
    getVerificationErrors,
    getCartErrors,
    getIsCheckoutMessage,
    getIsDeliveryFeeDiscounted,
    getIsDeliveryBypassDiscounted,
    getDeliveryErrors,
    getPromoMessage,
    getExpiredId,
    getFreeDeliveryMessage,
    getLoginOrSignupAttemptAlerts,
    getVerificationFlowErrors,
    getVerificationLinkResendStatus,
    getPasswordRecoveryErrors,
    getAnnouncements,
    getUnderAgeMinimumError,
    getCheckoutDepotMismatch,
    getCartMismatch,
    getCartDidExpire,
    getNeedToShowIDs,
    getNeedsPaymentType,
    getIsClosingSoon,
    getAppVersionMisMatch,
    getWasPromoRemoved,
    getIsCashOnly,
    getShouldShowAgeGate,
    shouldShow2faModal,
    shouldShowDuplicateAccountModal
  ],
  (
    locationOutofServiceAreaError,
    locationErrors,
    checkoutAlerts,
    isCanceledByDriver,
    atDailyLimit,
    statusErrors,
    verificationErrors,
    cartErrors,
    isCheckoutMessage,
    isDeliveryFeeDiscounted,
    isDeliveryBypassDiscounted,
    deliveryErrors,
    promoMessage,
    expiredId,
    freeDeliveryMessage,
    loginOrSignupAttemptAlerts,
    verificationFlowErrors,
    verificationResendStatus,
    passwordRecoveryErrors,
    announcements,
    underAgeMinimumError,
    depotMismatch,
    cartSizeMismatch,
    cartDidExpire,
    needToShowIDs,
    showNeedsPayment,
    closingSoon,
    versionMisMatch,
    wasPromoRemoved,
    isCashOnly,
    shouldShowAgeGate,
    show2faModal,
    showDuplicateIdModal
  ) => {
    return Object.assign(
      {},
      locationOutofServiceAreaError,
      locationErrors,
      checkoutAlerts,
      isCanceledByDriver,
      atDailyLimit,
      statusErrors,
      verificationErrors,
      cartErrors,
      isCheckoutMessage,
      isDeliveryFeeDiscounted,
      isDeliveryBypassDiscounted,
      deliveryErrors,
      promoMessage,
      expiredId,
      freeDeliveryMessage,
      loginOrSignupAttemptAlerts,
      verificationFlowErrors,
      verificationResendStatus,
      passwordRecoveryErrors,
      announcements,
      underAgeMinimumError,
      depotMismatch,
      cartSizeMismatch,
      cartDidExpire,
      needToShowIDs,
      showNeedsPayment,
      closingSoon,
      versionMisMatch,
      wasPromoRemoved,
      isCashOnly,
      shouldShowAgeGate,
      show2faModal,
      showDuplicateIdModal
    )
  }
)

/**
 * allow actions selectors - loop through errors and see if any error should prevent a keyed action
 */

const allowActionFactory = (factoryKey) => {
  return createSelector([getErrors], (errors) => {
    let enable = true
    Object.keys(errors).forEach((key) => {
      const prevented =
        errors[key] &&
        config[key] &&
        config[key].preventedActions &&
        config[key].preventedActions.indexOf(factoryKey) > -1
      if (prevented) {
        enable = false
      }
    })

    return enable
  })
}

export const allowAddToCart = allowActionFactory(ADD_TO_CART)

export const allowUploadId = allowActionFactory(BLOCK_UPLOAD_ID)

export const allowCheckout = allowActionFactory(CHECKOUT)

export const allowAddPayment = allowActionFactory(ADD_PAYMENT)

export const allowPlaceOrder = allowActionFactory(PLACE_ORDER)
/**
 * End allow action selectors
 */

// Loop through the errors by hierarchy - if the key is set to true from the
// getErrors selector and the config object has a route that matches the passed in,
// then we should send this error key to the banner
// Use this selector with connect + mapStateToProps in class components
export const getBannerKey = createSelector(
  [getErrors, getAlertVisibility, getCurrentPath, getCurrentQueryParams],
  (errors, alertStates, route, queryParams) => {
    let shownKey
    for (let i = 0; i < hierarchy.length; i++) {
      const key = hierarchy[i]
      if (alertStates[key] && errors[key] && isVisibleForType(BANNER, key, route, queryParams)) {
        shownKey = key
        break
      }
    }

    return shownKey
  }
)

// Use this selector with useSelector in functional components
// useSelector can't depend on props and getBannerKey depends on getCurrentPath/QueryParams wich depend on props.router
// Couple with getVisibleBannerKey below in the functional component to set bannerKey
export const getBannerKeys = createSelector([getErrors, getAlertVisibility], (errors, alertStates) => {
  const shownKeys = []
  for (let i = 0; i < hierarchy.length; i++) {
    const key = hierarchy[i]
    if (alertStates[key] && errors[key]) {
      shownKeys.push(key)
    }
  }

  return shownKeys
})
export function getVisibleBannerKey(bannerKeys, route, queryParams) {
  return bannerKeys.find((bannerKey) => isVisibleForType(BANNER, bannerKey, route, queryParams))
}

// For modals, additionally check if we should be showing a blocking modal immediately on page load
// or if we need to wait for an action to occur (e.g. clicking on the cart button while under cart minimum, etc)
export const getModalKey = createSelector(
  [getErrors, getAlertVisibility, getCurrentPath, getCurrentQueryParams],
  (errors, alertStates, route, queryParams) => {
    let shownKey
    for (let i = 0; i < hierarchy.length; i++) {
      const key = hierarchy[i]
      if (errors[key] && isVisibleForType(MODAL, key, route, queryParams)) {
        // show if we're on the right route and the alert is configured to show on load
        if (alertStates[key] && showOnLoad(key, route)) {
          shownKey = key
          break
        }

        // alternatively, show if we're attempting an action that the alert state is configured to show on
        if (
          config[key] &&
          config[key].modal &&
          config[key].modal.showOnAction.indexOf(alertStates.currentAction) > -1
        ) {
          shownKey = key
          break
        }
      }
    }

    return shownKey
  }
)
